import { Socket } from "socket.io-client";

let host = document.location.host;
let apiUrl = 'https://betaapi.myvirtualpal.com/'
if (host.includes('localhost')) {
  apiUrl = 'https://virtualpalapi.jcsoftwaresolution.in/'
} else if (host.includes('virtualpal.jcsoftwaresolution.in')) {
  apiUrl = 'https://virtualpalapi.jcsoftwaresolution.in/'
} else if (host.includes('jcsoftwaresolution.com')) {
  apiUrl = 'https://virtualpalapi.jcsoftwaresolution.in/'
} else {
  apiUrl = 'https://betaapi.myvirtualpal.com/'
}
let LocalNetwork = "http://192.168.1.39:6040/"
const environment = {
  OpenAIKey: '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',
  api: 'https://betaapi.myvirtualpal.com/',
  Socketurl: 'https://betachat.myvirtualpal.com/',
  adminRoleId: '64b152a909d268f038611929',
  userRoleId: '64b15102b14de6c28838f7d2',
  map_api_key: 'AIzaSyAbD0kk0SRu76yogIQKhY2r-oKdAZIbNIs',
  productTypeId: '64a7d198fa039f179c0320ca',
  AdminId: "64e5cb9f40ffa83e17ca35f8",
  stripe_publish_key: "pk_test_51KyZElEGnmCJcey2kk3glVh6N3xDcL9EGmxOOX6POD0xsaLNUJIBVZcNM4Kvg2Pe5Gyln1bn7Ge0WkdkbyQCRb1J00stuxPQRm",
  //  This is the reCaptcha google site key
  Captchasitekey: "6LclrOQpAAAAADXHlMwqulTfpxzyGeGi6JWUpj-t",
  //  THis is the TINYMCE EDITOR API KEY 
  tinyKey: 'zua062bxyqw46jy8bhcu8tz9aw6q37sb1pln5kwrnhnr319g',
  // These are the Zoom Marketplace Secrets thats we need during Zoom Integration
  ZoomClientId: "vaTvhtM1RbewD14ccaBnGw",
  ZoomClientSecret: "t4VRlCiDKCZk03xds38MrMYu3xmYfk1v",
  DirectManagerId: "65585b3ae6735f7cc5665522",
  AccountManagerId: "65585b4ce6735f7cc5665523",
  EmployerId: "64e5cdfc5bb9a041a39046d5",
  VirtualId: "64e83a928599356bddc2fa00",
  RecruiterId: "652393ee64451a10065b76eb",
  defaultSchedule: [
    { day: 'Sun', startTime: 'Off', lunchBreak: 'Off', endTime: 'Off' },
    { day: 'Mon', startTime: "9:00 AM", lunchBreak: '30 Min', endTime: "5:00 PM" },
    { day: 'Tue', startTime: "9:00 AM", lunchBreak: '30 Min', endTime: "5:00 PM" },
    { day: 'Wed', startTime: "9:00 AM", lunchBreak: '30 Min', endTime: "5:00 PM" },
    { day: 'Thu', startTime: "9:00 AM", lunchBreak: '30 Min', endTime: "5:00 PM" },
    { day: 'Fri', startTime: "9:00 AM", lunchBreak: '30 Min', endTime: "5:00 PM" },
    { day: 'Sat', startTime: 'Off', lunchBreak: 'Off', endTime: 'Off' },
  ],
  StarterSchedule: ["12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM", "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM"],
  EnderSchedule: ["12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM", "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM", "12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM", "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM", "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM"],
  LunchSchedule: ["30 Min", '45 Min']
};
export default environment;
